import React from "react";
import {Link} from "react-router-dom";

export default function Product() {
    return (
        <div className="row justify-content-center">
            <div className="col-md-3 card-box">
                <div className="card-body">
                    <h4>Our Products</h4>
                    <ul className="list-unstyled">
                        <li><Link to="/mobile">Mobiles</Link></li>
                        <li><Link to="/laptop">Laptops</Link></li>
                        <li><Link to="/ac">Ac</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

