import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { sendMessage, getMessage, getAllUsers } from '../../api-services/api';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { login, logout, selectIsLoggedIn, selectUser } from '../../redux/slices/authenticationSlice';
import { useSelector, useDispatch } from 'react-redux';
import Auth from '../../api-services/auth';
import { useHistory } from 'react-router-dom';
import ChatBoxList from './chatList';

const ChatBox = () => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const userProfile = useSelector(selectUser);
    const history = useHistory();
    const dispatch = useDispatch();
    const [messages, setMessages] = React.useState([]);
    const [loading, setLoading] = useState(true);
    const targetElementRef = useRef(null);
    const [showChatBox, setShowChatBox] = useState(true);
    const [userSelected, setUserSelected] = useState();
    const [isUserSelected, setIsUserSelected] = useState(true);
    const [users, setUsers] = useState(false);
    const [formData, setFormData] = useState({
        msgId: 0,
        chatDate: null,
        senderId: Number(sessionStorage.getItem('user')),
        senderMsg: '',
        receiverMsg: '',
        deletedFlag: 0,
    });
    const [errors, setErrors] = useState({});

    const handleChatChange = async () => {
        var ctBox = !showChatBox
        setShowChatBox(ctBox)
    };

    const handleChatClose = (event) => {
        setShowChatBox(true)
        setIsUserSelected(true)
        setUserSelected('');
    };

    const handleSelectChange = (event) => {
        setIsUserSelected(false)
        setUserSelected(event.target.value);
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};
        if (!formData.senderMsg.trim()) {
            newErrors.senderMsg = 'Text is required';
            isValid = false;
        }
        setErrors(newErrors);
        return isValid;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                const response = await sendMessage({msgData:formData, selectedUser:userSelected});
                const data = response.data;
                if (response) {
                    setFormData({ ...formData, senderMsg: '' });
                    setUserSelected(userSelected);
                    fetchChatData();
                    runFetchChat();
                    // setMessages((prevArray) => [...prevArray, data.data]);
                }
            } catch (error) {
                toast.warning("Something went wrong! please try after some time", { theme: "colored" })
            } finally {
            }
        }
    }
    
    const runFetchChat = async () => {
        setInterval(() => {
            fetchChatData();
        }, 3000);
    }

    const fetchChatData = async () => {
        setLoading(true);
        try {
            const response = await getMessage({ senderId: Number(sessionStorage.getItem('user')), receiverId: userSelected });
            const data = response.data;
            setMessages(data);
        } catch (error) {

        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    };

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const response = await getAllUsers(Number(sessionStorage.getItem('user')));
            const data = response.data;
            setUsers(data);
        } catch (error) {

        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const scrollToId = () => {
        if (targetElementRef.current) {
            targetElementRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    };

    return (
        // <Auth>
        <div>
            {
                showChatBox ?
                    <div className='chat-icon' onClick={() => { handleChatChange() }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M284 224.8a34.1 34.1 0 1 0 34.3 34.1A34.2 34.2 0 0 0 284 224.8zm-110.5 0a34.1 34.1 0 1 0 34.3 34.1A34.2 34.2 0 0 0 173.6 224.8zm220.9 0a34.1 34.1 0 1 0 34.3 34.1A34.2 34.2 0 0 0 394.5 224.8zm153.8-55.3c-15.5-24.2-37.3-45.6-64.7-63.6-52.9-34.8-122.4-54-195.7-54a406 406 0 0 0 -72 6.4 238.5 238.5 0 0 0 -49.5-36.6C99.7-11.7 40.9 .7 11.1 11.4A14.3 14.3 0 0 0 5.6 34.8C26.5 56.5 61.2 99.3 52.7 138.3c-33.1 33.9-51.1 74.8-51.1 117.3 0 43.4 18 84.2 51.1 118.1 8.5 39-26.2 81.8-47.1 103.5a14.3 14.3 0 0 0 5.6 23.3c29.7 10.7 88.5 23.1 155.3-10.2a238.7 238.7 0 0 0 49.5-36.6A406 406 0 0 0 288 460.1c73.3 0 142.8-19.2 195.7-54 27.4-18 49.1-39.4 64.7-63.6 17.3-26.9 26.1-55.9 26.1-86.1C574.4 225.4 565.6 196.4 548.3 169.5zM285 409.9a345.7 345.7 0 0 1 -89.4-11.5l-20.1 19.4a184.4 184.4 0 0 1 -37.1 27.6 145.8 145.8 0 0 1 -52.5 14.9c1-1.8 1.9-3.6 2.8-5.4q30.3-55.7 16.3-100.1c-33-26-52.8-59.2-52.8-95.4 0-83.1 104.3-150.5 232.8-150.5s232.9 67.4 232.9 150.5C517.9 342.5 413.6 409.9 285 409.9z" /></svg>
                    </div> :
                    <div className='chat-box'>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="chat-header">
                                            <h4>Chat with our agent</h4>
                                            <FontAwesomeIcon icon={faXmark} onClick={() => { handleChatClose() }} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        {
                                            isUserSelected ?
                                                <div className="chat-msg-area">
                                                    <select value={userSelected} className="form-control" onChange={handleSelectChange}>
                                                        <option value="">Select...</option>
                                                        {
                                                            users.map((item, i) => {
                                                                return (
                                                                    <option value={item.userId} key={i}>{item.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div> :
                                                <div className="chat-msg-area">
                                                    {
                                                        messages.length > 0 ?
                                                            messages.map((item, i) => {
                                                                return (
                                                                    <ChatBoxList {...item} key={i}></ChatBoxList>
                                                                )
                                                            })
                                                            :
                                                            <div className="col-md-12 p-4">
                                                                <div className="row">
                                                                    <div className="col-12 col-md-12 col-lg-12 p-0 mt-2">
                                                                        <div className="alert alert-warning">
                                                                            No message found
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                        }
                                    </div>
                                    <div className="col-md-12">
                                        <form onSubmit={handleSubmit}>
                                            <div className="chat-input-area">
                                                <div className="input-group">
                                                    <input type="text" className="form-control" id="senderMsg" name="senderMsg" value={formData.senderMsg} placeholder="Type here....." onChange={handleChange} />
                                                    <button type="submit" className="btn input-group-text"><img src='../../assets/images/icons/send-message.png' /></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    {/* <div className='chat-icon'>
                      <img src='../../../public/assets/images/icons/rocketchat.svg' />
                    </div> */}

                                    {
                                        // loading ?
                                        //     <div className="loader-container">
                                        //         <div className="loader">
                                        //         </div>
                                        //     </div> :
                                        //     <div className="row">
                                        //         {
                                        //             messages.length > 0 ?
                                        //             messages.map((item, i) => {
                                        //                     return (
                                        //                         <ChatBoxList {...item} key={i}></ChatBoxList>
                                        //                     )
                                        //                 })
                                        //                 :
                                        //                 <div className="col-md-12 p-4">
                                        //                     <div className="row">
                                        //                         <div className="col-12 col-md-12 col-lg-12 p-0 mt-2">
                                        //                             <div className="alert alert-warning">
                                        //                                 No msg found
                                        //                             </div>
                                        //                         </div>
                                        //                     </div>
                                        //                 </div>
                                        //         }

                                        //     </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
        // </Auth>
    )
}


export default ChatBox;
