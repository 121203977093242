import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import CartList from './CartList';
import { useDispatch } from 'react-redux';
import { addToCart, incrementQuantity, decrementQuantity, updateItem, removeFromCart, clearCart} from "../redux/actions/counterActions";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ViewCart = () => {
    const dispatch = useDispatch();
    const items = useSelector(state => state.cart);
    const [products, setProducts] = React.useState(items);

    function getSubTotal(d, q) {
        var subTotalPrice;
        subTotalPrice = d * q;
        return subTotalPrice;
    };

    const totalPrice = products.reduce((a, v) => a = a + (v.discountPrice * v.quantity), 0);

    var incQuantity = React.useCallback((item, i) => {
        var temp = [...products];
        temp[i] ? temp[i] = { ...temp[i], quantity: temp[i].quantity + 1 } : toast.warning("No data found in list", { theme: "colored" });; // Updating the quantity property
        setProducts(temp);
        dispatch(incrementQuantity(item))
    }, [products, setProducts]);

    var decQuantity = React.useCallback((item, i) => {
        var temp = [...products];
        temp[i] ? temp[i] = { ...temp[i], quantity: (item.quantity > 1 ? temp[i].quantity - 1 : 1) } : toast.warning("No data found in list", { theme: "colored" });; // Updating the quantity property
        setProducts(temp);
        dispatch(decrementQuantity(item))
    }, [products, setProducts]);

    var removeFromCartFn = React.useCallback((item2, ind) => {
        const index = products.findIndex(item => item.id === item2.id);
        let updatedCart = products.filter((_, i) => i !== index);
        setProducts(updatedCart);
        dispatch(updateItem(item2));
        toast.success("Item removed", { theme: "colored" });
    }, [products, setProducts]);

    return (
        <div className="row page-layout">
            <div className="col-md-12">
                <div className="row justify-content-center">
                    <div className="col-md-6 text-start">
                        <Link to="/shop" className="back-btn2"><FontAwesomeIcon icon={faArrowLeft} /></Link>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-6 pb-5">
                        <div className="row">
                            {
                                products.length > 0 ?
                                    <div className='col-md-12'>
                                        <div className="card grid-box-view">
                                            <div className='row'>
                                                <div className="col-md-6 product-title-text">
                                                    <h3>{products.length || 0} Items added in cart</h3>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='row'>
                                                        <div className="col-md-12 product-pricing-content-total justify-content-end">
                                                            <h1 className='color1'> ₹{totalPrice} </h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <div></div>
                            }
                            {
                                products.length > 0 ?
                                    products.map((item, i) => {
                                        return (
                                            <CartList {...item} incQuantity={incQuantity} decQuantity={decQuantity} removeFromCartFn={removeFromCartFn} getSubTotal={getSubTotal} i={i}></CartList>
                                        )
                                    }) :
                                    <div className="col-md-12 p-4">
                                        <div className="row">
                                            <div className="col-12 col-md-12 col-lg-12 p-0 mt-2">
                                                <div className="alert alert-warning">
                                                    No items in your cart
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }
                            {
                                products.length > 0 ?
                                    <div className='col-md-12'>
                                        <div className="card grid-box-view">
                                            <div className='row'>
                                                <div className="col-md-6 product-title-text">
                                                    <Link to="/checkout"><button type="button" className='btn btn-warning btn-theme4'>Proceed to checkout</button></Link>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='row'>
                                                        <div className="col-md-12 product-pricing-content-total justify-content-end">
                                                            <h1 className='color1'> ₹{totalPrice} </h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <div className='col-md-12'>
                                    </div>

                            }
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}


export default ViewCart;
