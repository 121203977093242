import React from "react";

export default function Seodm() {
    return (
        <div className="row justify-content-center">
            <div className="col-md-12 card-box">
                <div className="card-body">
                    <p>Search engine optimization is the process of improving the quality and quantity of website traffic to a website or a web page from search engines. SEO targets unpaid traffic rather than direct traffic or paid traffic.</p>
                </div>
            </div>
        </div>
    )
}

