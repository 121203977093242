import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faTrash } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch} from 'react-redux';

const CartBox = (props) => {
    return (
        <div className='col-md-12' key={props.i}>
            <div className="card grid-box-view">
                <div className='row'>
                    <div className='col-md-2 flex-layout'>
                        <img src={props.imageUrl
                        } className='img-fluid' alt='' />
                    </div>
                    <div className='col-md-7'>
                        <div className='row'>
                            <div className="col-md-12 product-title">
                                <h3>{props.name}</h3>
                            </div>
                            <div className="col-md-12 title-gradeout">
                                <h5>{props.category}</h5>
                            </div>
                            <div className="col-md-12 product-pricing-content">
                                <h1 className='color1'> ₹{props.discountPrice} </h1>
                            </div>
                            <div className="col-md-6 product-pricing-content">
                                <div className="input-group customize-input-group">
                                    <span className="input-group-text" onClick={() => { props.decQuantity(props,props.i) }}><FontAwesomeIcon icon={faMinus} /></span>
                                    <input type="text" className="form-control" placeholder="Username" name="usrname" value={props.quantity} disabled/>
                                    <span className="input-group-text" onClick={() => { props.incQuantity(props,props.i) }}><FontAwesomeIcon icon={faPlus} /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className='row'>
                            <div className="col-md-12 product-pricing-content product-pricing-flex">
                                <div className='product-pricing-discounted'>
                                    <h1 className='color1'> ₹{props.getSubTotal(props.discountPrice, props.quantity)}</h1>
                                    {/* <h1 className='color1'> <span className='text-decoration-line-through'>₹{props.price}</span>&nbsp;<span className="badge bg-secondary badge-percent2">{props.discount}</span></h1> */}
                                </div>
                            </div>
                        </div>
                        <FontAwesomeIcon icon={faTrash} className='delete-btn' onClick={() => { props.removeFromCartFn(props,props.i) }}/>
                    </div>
                </div>
            </div>
        </div>                     
    )
}


export default CartBox;
