import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, incrementQuantity, decrementQuantity, updateItem, removeFromCart, clearCart} from "../../redux/actions/counterActions";
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import { getOrderDetails } from '../../api-services/api';
import { login, logout, selectIsLoggedIn, selectUser } from '../../redux/slices/authenticationSlice';
import Auth from '../../api-services/auth';

const OrderStatus = () => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [orderDetails, setOrderDetails] = useState();
    const params = useParams();
    const fetchProfileData = async () => {
        setLoading(true);
        try {
            const response = await getOrderDetails({orderTokenId:params.orderTokenId});
            if (response) {
                const data = response.data;
                setOrderDetails(data);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    };

    const printDateAndTime = (date) => {
        const fullDate = new Date(date);
        const options = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true, // Use 24-hour format
            timeZone: 'UTC' // Specify the time zone if needed
        };
        const formattedDateTime = fullDate.toLocaleString('en-US', options);
        return formattedDateTime;
    };

    useEffect(() => {
        if (isLoggedIn) {
            fetchProfileData();
        }
    }, []);

    return (
        <Auth>
            <div className="row page-layout">
                <div className="col-md-12">
                    <div className="row justify-content-center">
                        <div className="col-md-6 pb-5">
                            <div className="row">
                                <div className="col-md-12">
                                    {
                                        loading ?
                                            <div className="loader-container">
                                                <div className="loader">
                                                </div>
                                            </div> :
                                            <div className='card status-success'>
                                                <div className='row justify-content-center'>
                                                    <div className="col-md-6 d-flex justify-content-center">
                                                        <div className="stat-icon mb-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 text-center msg-text">
                                                        Hey {orderDetails.user.name}
                                                    </div>
                                                    <div className="col-md-12 text-center msg-heading">
                                                        Your Order is Confirmed!
                                                    </div>
                                                    <div className="col-md-12 text-center msg-text">
                                                        We will send you a shipping confirmation email as soon as your order ships
                                                    </div>
                                                    <div className="col-md-12">
                                                        <ul className="list-unstyled order-list">
                                                            <li>ORDER ID <span>{orderDetails.orderId}</span></li>
                                                            <li>CUSTOMER <span>{orderDetails.user.name}</span></li>
                                                            <li>CONTACT <span>{orderDetails.user.phone}</span></li>
                                                            <li>DISCOUNT <span>-</span></li>
                                                            <li>ORDER DATE <span>{printDateAndTime(orderDetails.orderDate) || '-'}</span></li>
                                                            <li>ORDER TOTAL <span className="total-paid">PAID {orderDetails.totalPrice}</span></li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-4 mt-3 text-center">
                                                        <Link to="/shop"><button type="submit" className='btn btn-warning btn-theme4'><FontAwesomeIcon icon={faCartShopping} />SHOP MORE</button></Link>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Auth>
    )
}


export default OrderStatus;
