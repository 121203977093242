import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCity, getCountry, getState, saveProfile } from '../../api-services/api';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { login, logout, selectIsLoggedIn, selectUser } from '../../redux/slices/authenticationSlice';

const Register = () => {
    const history = useHistory();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [isExistError, setExistError] = useState(false);
    const [formData, setFormData] = useState({
        userId: 0,
        name: '',
        phone: '',
        email: '',
        password:'',
        profileImg: '',
        profileImgSource: '',
        gender: '',
        deletedFlag: 0
    });
    const [errors, setErrors] = useState({});
    const [selectedCountry, setSelectedCountry] = useState();
    const [selectedState, setSelectedState] = useState();
    const [selectedCity, setSelectedCity] = useState();
    const [selectedGender, setSelectedGender] = useState();
    const [country, setCountry] = useState([]);
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [gender, setGender] = useState([{ genderId: 1, name: 'Male' }, { genderId: 2, name: 'Female' }]);

    const handleLogin = (data) => {
        dispatch(login(data));
      };


    const handleGender = (event) => {
        setSelectedGender(event.target.value);
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
            isValid = false;
        }
       
        if (!formData.phone.trim()) {
            newErrors.phone = 'phone is required';
            isValid = false;
        } else if (!/^[\s\d()-]+$/.test(formData.phone)) {
            newErrors.phone = 'Invalid phone format';
            isValid = false;
        }
        if (!formData.email) {
            newErrors.email = 'email is required';
            isValid = false;
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            newErrors.email = 'Invalid email format';
            isValid = false;
        }
        if (!formData.password) {
            newErrors.password = 'password is required';
            isValid = false;
        } 
        // else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.password)) {
        //     newErrors.password = 'Invalid password format';
        //     isValid = false;
        // }
        if (!selectedGender) {
            newErrors.gender = 'Gender is required';
            isValid = false;
        }
        setErrors(newErrors);
        return isValid;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                // const newFormData = new FormData();
                // newFormData.append('selectedCountry', selectedCountry);
                // newFormData.append('selectedState', selectedState);
                // newFormData.append('selectedCity', selectedCity);
                // newFormData.append('selectedGender', selectedGender);
                // newFormData.append('basicInfo', formData);
                const response = await saveProfile(formData);
                if (response) {
                    if (response.data.type == 'exist') {
                     setExistError(true);
                    }
                    else
                    {
                        toast.success("Registered successfully", { theme: "colored" });
                        handleLogin(response.data.user);
                        sessionStorage.setItem('user', response.data.user.userId);
                        history.push('/profile');
                        setErrors({});
                    }
                }
            } catch (error) {
                // toast.warning("Something went wrong! please try after some time", { theme: "colored" })
            } finally {
            }
        }
    };

    useEffect(() => {
    }, []);

    return (
        <div className="row justify-content-center page-layout">
            <div className="col-md-6">
                <div className='card p-4 mt-4'>
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12 mt-2 text-center">
                            <form onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        {
                                            isExistError ?
                                                <div className='alert alert-danger'>
                                                    User already exist
                                                </div> :
                                                null
                                        }
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 col-xl-6 form-group">
                                        <input type="text" className="form-control" id="name" value={formData.name} onChange={handleChange} placeholder="Your name i.e John doe" name="name" />
                                        {errors.name && <span className="invalid-msg">{errors.name}</span>}
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 col-xl-6 form-group">
                                        <input type="number" className="form-control" id="phone" value={formData.phone} onChange={handleChange} placeholder="Your phone i.e 9876543210" name="phone" />
                                        {errors.phone && <span className="invalid-msg">{errors.phone}</span>}
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 col-xl-6 form-group">
                                        <input type="email" className="form-control" id="email" value={formData.email} onChange={handleChange} placeholder="Your email i.e abc@gmail.com" name="email" />
                                        {errors.email && <span className="invalid-msg">{errors.email}</span>}
                                    </div>
                                    <div className="col-6 col-md-6 col-lg-6 col-xl-6 pt-3 form-group d-inline-flex">
                                        <span className="form-label-radio">Select Gender</span>
                                        {
                                            gender.map((item, i) => (
                                                <label className="form-check-label" key={i}>
                                                    <div className="form-check custom-size-check">
                                                        <input type="radio" className="form-check-input" id={item.genderId} name='gender' value={item.genderId} onChange={handleGender} />{item.name}
                                                    </div>
                                                </label>
                                            ))
                                        }
                                        {errors.gender && <span className="invalid-msg">{errors.gender}</span>}
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-12 form-group">
                                        <input type="password" className="form-control" id="password" value={formData.password} onChange={handleChange} placeholder="**********" name="password" />
                                        {errors.password && <span className="invalid-msg">{errors.password}</span>}
                                    </div>
                                </div>
                                <button type='submit' className='btn btn-success btn-custom btn-h-40 W-100'>SAVE</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Register;
