import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import CartBox from './cartBox';
import { addToCart, incrementQuantity, decrementQuantity, updateItem, removeFromCart, clearCart} from "../../redux/actions/counterActions";
import { getCity, getCountry, getState, placeOrder, getProfile, getAddress } from '../../api-services/api';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import Auth from '../../api-services/auth';
import { login, logout, selectIsLoggedIn, selectUser } from '../../redux/slices/authenticationSlice';
const Checkout = () => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const dispatch = useDispatch();
    const history = useHistory();
    const items = useSelector(state => state.cart);
    const [address, setAddress] = React.useState([]);
    const [products, setProducts] = React.useState(items);
    const [selectedDelAddress, setSelectedDelAddress] = useState();
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        userId: Number(sessionStorage.getItem('user')),
        name: '',
        phone: '',
        email: '',
        apartmentName: '',
        roomNo: '',
        address1: '',
        address2: '',
        landmark: '',
        pincode: '',
        addressType: '',
        deletedFlag: 0
    });
    const [errors, setErrors] = useState({});
    const [selectedGender, setSelectedGender] = useState();

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};
        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
            isValid = false;
        }
        if (!formData.phone.trim()) {
            newErrors.phone = 'phone is required';
            isValid = false;
        } else if (!/^[\s\d()-]+$/.test(formData.phone)) {
            newErrors.phone = 'Invalid phone format';
            isValid = false;
        }
        if (!formData.email) {
            newErrors.email = 'email is required';
            isValid = false;
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            newErrors.email = 'Invalid email format';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                var obj = {
                    orderId: 0,
                    indexId: 0,
                    orderTokenId: 0,
                    user: formData,
                    address: selectedDelAddress,
                    items: products,
                    totalPrice: totalPrice
                }
                const response = await placeOrder(obj);
                if (response.data.orderTokenId) {
                    toast.success("Order placed", { theme: "colored" });
                    dispatch(updateItem({}));
                    setErrors({});
                    history.push('/ord_staus/' + response.data.orderTokenId);
                }
                else {
                    toast.warning("Something went wrong! please try after some time", { theme: "colored" })
                }
            } catch (error) {
                toast.warning("Something went wrong! please try after some time", { theme: "colored" })
            } finally {
            }
        }
    };

    const fetchProfileData = async () => {
        setLoading(true);
        try {
            const response = await getProfile(Number(sessionStorage.getItem('user')));
            if (response) {
                const data = response.data;
                setFormData(data);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    };

    function getSubTotal(d, q) {
        var subTotalPrice;
        subTotalPrice = d * q;
        return subTotalPrice;
    };

    const totalPrice = products.reduce((a, v) => a = a + (v.discountPrice * v.quantity), 0);

    var incQuantity = React.useCallback((item, i) => {
        var temp = [...products];
        temp[i] ? temp[i] = { ...temp[i], quantity: temp[i].quantity + 1 } : toast.warning("No data found in list", { theme: "colored" });; // Updating the quantity property
        setProducts(temp);
        dispatch(incrementQuantity(item))
    }, [products, setProducts]);

    var decQuantity = React.useCallback((item, i) => {
        var temp = [...products];
        temp[i] ? temp[i] = { ...temp[i], quantity: (item.quantity > 1 ? temp[i].quantity - 1 : 1) } : toast.warning("No data found in list", { theme: "colored" });; // Updating the quantity property
        setProducts(temp);
        dispatch(decrementQuantity(item))
    }, [products, setProducts]);

    var removeFromCartFn = React.useCallback((item2, ind) => {
        const index = products.findIndex(item => item.id === item2.id);
        let updatedCart = products.filter((_, i) => i !== index);
        setProducts(updatedCart);
        dispatch(updateItem(item2));
        toast.success("Item removed", { theme: "colored" });
    }, [products, setProducts]);

    const fetchAddressData = async () => {
        setLoading(true);
        try {
            const response = await getAddress( Number(sessionStorage.getItem('user')));
            const data = JSON.parse(response.data);
            setAddress(data);
        } catch (error) {

        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    };

    const handleDelAddress = (item) => {
        setSelectedDelAddress(item);
    };

    useEffect(() => {
        if (isLoggedIn) {
            fetchProfileData();
            fetchAddressData();
        }
    }, [setFormData]);

    return (
        <Auth>
            <div className="row page-layout">
                <div className="col-md-12">
                    <div className="row justify-content-center">
                        <div className="col-md-10 pb-5">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6 fixed-height-row">
                                        <div className="card grid-box-view sticky-form">
                                            <div className='row'>
                                                <div className='col-md-12 pb-3'>
                                                    <Link to="/shop" className="back-btn2"><FontAwesomeIcon icon={faArrowLeft} /></Link>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-12 col-md-12 col-lg-12 col-xl-12 form-group">
                                                    <input disabled type="text" className="form-control" id="name" value={formData.name} onChange={handleChange} placeholder="Your name i.e John doe" name="name" />
                                                    {errors.name && <span className="invalid-msg">{errors.name}</span>}
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12 col-xl-12 form-group">
                                                    <input disabled type="number" className="form-control" id="phone" value={formData.phone} onChange={handleChange} placeholder="Your phone i.e 9876543210" name="phone" />
                                                    {errors.phone && <span className="invalid-msg">{errors.phone}</span>}
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12 col-xl-12 form-group">
                                                    <input disabled type="email" className="form-control" id="email" value={formData.email} onChange={handleChange} placeholder="Email i.e abc@gmail.com" name="email" />
                                                    {errors.email && <span className="invalid-msg">{errors.email}</span>}
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12 col-xl-12 text-start">
                                                    <h4 className='input-labels mb-3 pt-4'>Delivery Address</h4>
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                                    <div className='row'>
                                                        <div className='col-md-12'>
                                                            {
                                                                address.map((item, i) => (
                                                                    <label className="form-check-label custom-radio-btn border-box" key={i}>
                                                                        <div className="form-check">
                                                                            <input type="radio" className="form-check-input" id={item.addressId} name='defAddress' value={item.addressId} onChange={() => { handleDelAddress(item) }} /><span className='bold-text'>{item.name}</span><br /> {item.roomNo}, {item.apartmentName}, {item.address2}, {item.address2},<br /> <span className='bold-text'>Pincode</span> : {item.pincode}
                                                                        </div>
                                                                    </label>
                                                                ))
                                                            }
                                                        </div>
                                                        <div className='col-md-12'>
                                                            <Link to="/viewAddress"><button type="button" className='btn btn-warning btn-theme4'>ADD NEW ADDRESS</button></Link>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        {
                                            products.length > 0 ?
                                                <div className='col-md-12'>
                                                    <div className="card grid-box-view">
                                                        <div className='row'>
                                                            <div className="col-md-6 product-title-text">
                                                                <h3>{products.length || 0} Items added in cart</h3>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <div className='row'>
                                                                    <div className="col-md-12 product-pricing-content-total justify-content-end">
                                                                        <h1 className='color1'> ₹{totalPrice} </h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> :
                                                <div></div>
                                        }
                                        {
                                            products.length > 0 ?
                                                products.map((item, i) => {
                                                    return (
                                                        <CartBox {...item} incQuantity={incQuantity} decQuantity={decQuantity} removeFromCartFn={removeFromCartFn} getSubTotal={getSubTotal} i={i} key={i}></CartBox>
                                                    )
                                                }) :
                                                <div className="col-md-12 p-4">
                                                    <div className="row">
                                                        <div className="col-12 col-md-12 col-lg-12 p-0 mt-2">
                                                            <div className="alert alert-warning">
                                                                No items in your cart
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                        {
                                            products.length > 0 ?
                                                <div className='col-md-12'>
                                                    <div className="card grid-box-view">
                                                        <div className='row'>
                                                            <div className="col-md-6 product-title-text">
                                                                <button type="submit" className='btn btn-warning btn-theme4'>Place Order</button>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <div className='row'>
                                                                    <div className="col-md-12 product-pricing-content-total justify-content-end">
                                                                        <h1 className='color1'> ₹{totalPrice} </h1>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> :
                                                <div className='col-md-12'>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Auth>
    )
}


export default Checkout;
