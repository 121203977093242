import React from "react";
import { Link, Route } from "react-router-dom";
import Consultation from "./Services/Consultation";
import Training from "./Services/Training";
import Seodm from "./Services/Seodm";

export default function Services() {
    return (
        <div className="row justify-content-center">
            <div className="col-md-3 card-box">
                <div className="card-body">
                    <h4>Our Services</h4>
                    <ul className="list-unstyled">
                        <li><Link to="/services/consultation">Consultation</Link></li>
                        <li><Link to="/services/training">Training</Link></li>
                        <li><Link to="/services/SEODM">Socal Media & Digital Marketing</Link></li>
                    </ul>
                    <Route path="/services/consultation">
                        <Consultation></Consultation>
                    </Route>
                    <Route path="/services/training">
                        <Training></Training>
                    </Route>
                    <Route path="/services/SEODM">
                        <Seodm></Seodm>
                    </Route>
                </div>
            </div>
        </div>
    )
}

