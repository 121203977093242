const ADDTOCART = "ADDTOCART";
const INCREMENTQTY = "INCREMENTQTY";
const DECREMENTQTY = "DECREMENTQTY";
const UPDATEITEM = "UPDATEITEM";
const CLEARCART = "CLEARCART";
const REMOVEITEM = "REMOVEITEM";

const incrementQuantity = (payload) => ({
    type: INCREMENTQTY,
    payload
});

const decrementQuantity = (payload) => {
    return {
        type: DECREMENTQTY,
        payload
    };
};

const updateItem = (payload) => {
    return {
        type: UPDATEITEM,
        payload
    };
};

const removeFromCart = (payload) => {
    return {
        type: REMOVEITEM,
        payload
    };
};

const addToCart = (payload) => ({
    type: ADDTOCART,
    payload
});

const clearCart = (payload) => {
    return {
        type: CLEARCART,
        payload
    };
};

export { addToCart, incrementQuantity, decrementQuantity, updateItem, removeFromCart, clearCart, ADDTOCART, REMOVEITEM, UPDATEITEM, CLEARCART, INCREMENTQTY, DECREMENTQTY };