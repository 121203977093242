import { configureStore } from "@reduxjs/toolkit";
// import { cartReducer } from "./slices/cartSlice";
import { cartReducer } from "./reducers/cartReducer";
import authenticationReducer from './slices/authenticationSlice';

const saveToSessionStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem('state', serializedState);
  } catch (error) {
    console.error('Error saving to local storage:', error);
  }
};

const loadFromSessionStorage = () => {
  try {
    const serializedState = sessionStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    console.error('Error loading from local storage:', error);
    return undefined;
  }
};

const middleware = (store) => (next) => (action) => {
  next(action);
  saveToSessionStorage(store.getState());
};

const preloadedState = loadFromSessionStorage();

// const store = configureStore({
//   reducer:{
//     cart:cartReducer,
//   },
//   middleware: getDefaultMiddleware =>
//   getDefaultMiddleware({
//     serializableCheck: false,
//   }),
// });

const store = configureStore({
  reducer: {
    cart: cartReducer,
    authentication: authenticationReducer,
  },
  preloadedState: preloadedState, // Provide preloadedState directly
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middleware), // Include your custom middleware
});

export default store;