import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faCameraRetro } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getProfile, getCity, getCountry, getState, saveProfile, saveProfilePhoto } from '../../api-services/api';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useParams } from 'react-router-dom'
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { login, logout, selectIsLoggedIn, selectUser } from '../../redux/slices/authenticationSlice';
import Auth from '../../api-services/auth';

const Profile = () => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const dispatch = useDispatch();
    const params = useParams();
    const [profile, setProfile] = React.useState();
    const [loading, setLoading] = useState(true);
    const [isImageError, setIsImageError] = useState(false);
    const targetElementRef = useRef(null);
    const [formData, setFormData] = useState({
        userId: Number(sessionStorage.getItem('user')),
        name: '',
        phone: '',
        email: '',
        password: '',
        profileImg: '',
        profileImgSource: '',
        country: '',
        state: '',
        city: '',
        pincode: '',
        gender: '',
        deletedFlag: 0
    });
    const [errors, setErrors] = useState({});
    const [selectedOption, setSelectedOption] = useState();
    const [selectedFile, setSelectedFile] = useState(null);
    const [base64Data, setBase64Data] = useState(null);

    const [selectedGender, setSelectedGender] = useState();
    const [gender, setGender] = useState([{ genderId: 1, name: 'Male' }, { genderId: 2, name: 'Female' }]);

    const [uploadedFile, setUploadedFile] = useState(null);

    const handleLogin = (data) => {
        dispatch(login(data));
    };

    const handleGender = (event) => {
        setSelectedGender(event.target.value);
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const tooltipDefault = (
        <Tooltip id="tooltip">
            Click to change profile image
        </Tooltip>
    );

    const handleDefaultOptionChange = (event) => {
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};
        if (!formData.name) {
            newErrors.name = 'Name is required';
            isValid = false;
        }
        if (!formData.phone.trim()) {
            newErrors.phone = 'phone is required';
            isValid = false;
        } else if (!/^[\s\d()-]+$/.test(formData.phone)) {
            newErrors.phone = 'Invalid phone format';
            isValid = false;
        }
        if (!formData.email) {
            newErrors.email = 'email is required';
            isValid = false;
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            newErrors.email = 'Invalid email format';
            isValid = false;
        }
        if (!selectedGender) {
            newErrors.gender = 'Gender is required';
        }
        setErrors(newErrors);
        return isValid;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                const response = await saveProfile(formData);
                if (response) {
                    toast.success("Profile saved", { theme: "colored" });
                    fetchProfileData();
                    setErrors({});
                }
            } catch (error) {
                toast.warning("Something went wrong! please try after some time", { theme: "colored" })
            } finally {
            }
        }
    };

    const fetchProfileData = async () => {
        setLoading(true);
        try {
            const response = await getProfile(sessionStorage.getItem('user'));
            if (response) {
                const data = response.data;
                setFormData(data);
                handleLogin(response.data);
                setSelectedGender(data.gender);
                setUploadedFile(formData.profileImgSource);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    };

    useEffect(() => {
        if (isLoggedIn) {
            fetchProfileData();
        }
    }, [setFormData]);


    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        const extname = file.type;
        if (allowedFileTypes.includes(extname)) {
            setIsImageError(false);
            try {
                setLoading(true);
                const newFormData = new FormData();
                newFormData.append('profileImg', file);
                newFormData.append('userId', formData.userId);
                const response = await saveProfilePhoto(newFormData);
                if (response) {
                    toast.success("Profile saved", { theme: "colored" });
                    fetch(response.data.file)
                        .then(response => {
                            if (!response.ok) {
                                throw new Error('Network response was not ok');
                            }
                            return response.blob();
                        })
                        .then(blob => {
                            const objectURL = URL.createObjectURL(blob);
                            setUploadedFile(objectURL);
                            setLoading(false);
                        })
                    setErrors({});
                }
            } catch (error) {
                toast.warning("Something went wrong! please try after some time", { theme: "colored" })
            } finally {
            }
        } else {
            setIsImageError(true);
        }
    };

    return (
        <Auth>
            <div className="row justify-content-center page-layout">
                <div className="col-md-6 pb-5 fixed-height-content">
                    {
                        loading ?
                            <div className="loader-container">
                                <div className="loader">
                                </div>
                            </div> :
                            <div className="row">
                                <Link to="/shop" className="back-btn"><FontAwesomeIcon icon={faArrowLeft} /></Link>
                                {
                                    <div className="col-md-12" id="targetElement" ref={targetElementRef}>
                                        <div className='card p-4 mt-4'>
                                            <div className="row">
                                                <div className="col-12 col-md-12 col-lg-12 mt-2 text-center">
                                                    <form onSubmit={handleSubmit}>
                                                        <div className='row'>
                                                            <div className="col-md-12">
                                                                <div className="row justify-content-center align-content-center">
                                                                    <div className="col-md-4">
                                                                        <div className="profile-box">
                                                                            <img src={uploadedFile || formData.profileImgSource} className="profile-img"></img>
                                                                            <OverlayTrigger placement="top" overlay={tooltipDefault}>
                                                                                <label className="camera-upload" htmlFor="profileImg">
                                                                                    <FontAwesomeIcon icon={faCameraRetro} className="camera-upload-icon" />
                                                                                </label>
                                                                            </OverlayTrigger>
                                                                            <input type="file" name="profileImg" id="profileImg" accept="image/*" onChange={handleFileChange} hidden />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-12'>
                                                                        {
                                                                            isImageError ?
                                                                                <div className='alert alert-danger'>
                                                                                    Invalid file type. Only JPG, JPEG, and PNG are allowed
                                                                                </div> :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-12 col-lg-12 col-xl-12 text-start">
                                                                <h4 className='input-labels mb-3'>Basic Info</h4>
                                                            </div>
                                                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 form-group">
                                                                <input type="text" className="form-control" id="name" value={formData.name} onChange={handleChange} placeholder="Your name i.e John doe" name="name" />
                                                                {errors.name && <span className="invalid-msg">{errors.name}</span>}
                                                            </div>
                                                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 form-group">
                                                                <input type="number" className="form-control" id="phone" value={formData.phone} onChange={handleChange} placeholder="Your phone i.e 9876543210" name="phone" />
                                                                {errors.phone && <span className="invalid-msg">{errors.phone}</span>}
                                                            </div>
                                                            <div className="col-12 col-md-6 col-lg-6 col-xl-6 form-group">
                                                                <input type="email" className="form-control" id="email" value={formData.email} onChange={handleChange} placeholder="Email i.e abc@gmail.com" name="email" />
                                                                {errors.email && <span className="invalid-msg">{errors.email}</span>}
                                                            </div>
                                                            <div className="col-6 col-md-6 col-lg-6 col-xl-6 form-group pt-3 d-inline-flex">
                                                                <span className="form-label-radio">Select Gender</span>
                                                                {
                                                                    gender.map((item, i) => (
                                                                        <label className="form-check-label" key={i}>
                                                                            <div className="form-check custom-size-check">
                                                                                <input type="radio" className="form-check-input" id={item.genderId} name='gender' value={item.genderId} onChange={handleGender} checked={selectedGender == item.genderId} />{item.name}
                                                                            </div>
                                                                        </label>
                                                                    ))
                                                                }
                                                                {errors.gender && <span className="invalid-msg">{errors.gender}</span>}
                                                            </div>
                                                        </div>
                                                        <button type='submit' className='btn btn-success btn-custom btn-h-40 W-100'>SAVE</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                    }
                </div>
            </div>
        </Auth>
    )
}


export default Profile;
