import React from "react";
import { Link } from 'react-router-dom';

function ShopList(props){
    return (
        <div className="col-md-3" key={props.i}>
             <Link to={`/productDetails/${props.id}`} state={{ props }}>
            <div className="card grid-box">
                <div className="row">
                    <div className="offer-section">{props.discount} OFF</div>
                    <div className="col-md-12 item-image">
                        <img src={props.imageUrl} alt={props.name} title={props.name} className="img-fluid" />
                    </div>
                    <div className="col-md-12 text-title">
                        <h5>{props.name}</h5>
                    </div>
                    <div className="col-md-12 text-title">
                        <h5><span className="text-decoration-line-through color1">₹{props.price}</span> &nbsp;&nbsp;₹{props.discountPrice}</h5>
                    </div>
                    <div className="col-md-12 text-desc">
                        <p>{props.desc}</p>
                    </div>
                    <div className="col-md-12 text-start">
                        {
                            // props.status ? <button className="btn btn-warning btn-theme2" onClick={() => { props.removeFromCartFn(props,props.i) }}>Remove</button> :
                                // <button className="btn btn-warning btn-theme2" onClick={() => { props.addToCartFn(props,props.i) }}>Add to cart</button>
                        }
                    </div>
                    <div className="col-md-12 text-end">
                       <button className="btn btn-info btn-theme2">Buy Now</button>
                    </div>
                </div>
            </div>
            </Link>
        </div>
    )
}

export default React.memo(ShopList);