import React from "react";

export default function Home() {
    return (
        <div className="row justify-content-center">
            <div className="col-md-8 text-center card-box">
                <div className="card-body">
                    <h1>This is home page</h1>
                </div>
            </div>
        </div>
    )
}

