import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import AddressList from './AddressList';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { saveAddress, getAddress, updateAddress, getProfile, getCity, getCountry, getState } from '../../api-services/api';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { login, logout, selectIsLoggedIn, selectUser } from '../../redux/slices/authenticationSlice';
import { useSelector, useDispatch } from 'react-redux';
import Auth from '../../api-services/auth';
import { useHistory } from 'react-router-dom';

const UserAddress = () => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const userProfile = useSelector(selectUser);
    const history = useHistory();
    const dispatch = useDispatch();
    const [address, setAddress] = React.useState([]);
    const [loading, setLoading] = useState(true);
    const targetElementRef = useRef(null);
    const [formData, setFormData] = useState({
        userId: Number(sessionStorage.getItem('user')),
        addressId: 0,
        name: '',
        phone: '',
        apartmentName: '',
        roomNo: '',
        address1: '',
        address2: '',
        country: '',
        countryName: '',
        state: '',
        stateName: '',
        city: '',
        cityName: '',
        landmark: '',
        pincode: '',
        addressType: '',
        deletedFlag: 0,
        isDefault: 0
    });
    const [errors, setErrors] = useState({});
    const [toggleForm, setToggleForm] = useState(false);
    const [modalStyle, setModalStyle] = useState({ display: 'none' });
    const [tempObj, setTempObj] = useState({});
    const [selectedOption, setSelectedOption] = useState();
    const [addType, setAddType] = useState([{ id: 1, name: 'Home' }, { id: 2, name: 'Office' }, { id: 3, name: 'Other' }]);
    const [selectedAddType, setSelectedAddType] = useState();
    const [selectedCountry, setSelectedCountry] = useState();
    const [selectedState, setSelectedState] = useState();
    const [selectedCity, setSelectedCity] = useState();
    const [country, setCountry] = useState([]);
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);

    const handleCountry = (event) => {
        var obj = country.find((item) => item.countryId == event.target.value)
        setSelectedCountry(event.target.value);
        fetchStates(event.target.value);
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
            countryName: obj.name
        }));
    };

    const handleState = (event) => {
        var obj = state.find((item) => item.stateId == event.target.value)
        setSelectedState(event.target.value);
        fetchCities(selectedCountry, event.target.value);
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
            stateName: obj.name
        }));
    };

    const handleCity = (event) => {
        var obj = city.find((item) => item.cityId == event.target.value)
        setSelectedCity(event.target.value);
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
            cityName: obj.name
        }));
    };

    const fetchCountries = async () => {
        setLoading(true);
        try {
            const response = await getCountry(0);
            const data = JSON.parse(response.data);
            setCountry(data);
        } catch (error) {
            console.log(error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    };

    const fetchStates = async (countryId) => {
        // setLoading(true);
        try {
            const response = await getState(countryId);
            const data = response.data;
            setState(data);
        } catch (error) {
            console.log(error);
        } finally {
            // setTimeout(() => {
            //     setLoading(false);
            // }, 1000);
        }
    };

    const fetchCities = async (countryId, stateId) => {
        // setLoading(true);
        try {
            const response = await getCity(countryId, stateId);
            const data = response.data;
            setCity(data);
        } catch (error) {
            console.log(error);
        } finally {
            // setTimeout(() => {
            //     setLoading(false);
            // }, 1000);
        }
    };

    const handleDefaultOptionChange = (event) => {
        let filteredAddress = address.find((item) => item.addressId == event.target.value);
        setSelectedOption(filteredAddress);
    };

    const handleAddressOptionChange = (event) => {
        setSelectedAddType(event.target.value);
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    function toggleAddress() {
        scrollToId();
        setFormData({
            userId: Number(sessionStorage.getItem('user')),
            addressId: 0,
            name: userProfile.name,
            phone: userProfile.phone,
            apartmentName: '',
            roomNo: '',
            address1: '',
            address2: '',
            country: selectedCountry,
            countryName: '',
            state: selectedState,
            stateName: '',
            city: selectedCity,
            cityName: '',
            landmark: '',
            pincode: '',
            addressType: selectedAddType,
            deletedFlag: 0,
            isDefault: false
        })
        setToggleForm(true)
    }

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
            isValid = false;
        }
        if (!formData.apartmentName.trim()) {
            newErrors.apartmentName = 'Apertment name is required';
            isValid = false;
        }
        if (!selectedCountry) {
            newErrors.country = 'Country name is required';
            isValid = false;
        }
        if (!selectedState) {
            newErrors.state = 'State is required';
            isValid = false;
        }
        if (!selectedCity) {
            newErrors.city = 'City 1 is required';
            isValid = false;
        }
        if (!formData.roomNo.trim()) {
            newErrors.roomNo = 'Room no is required';
            isValid = false;
        }
        if (!formData.address1.trim()) {
            newErrors.address1 = 'Address 1 is required';
            isValid = false;
        }
        if (!formData.landmark.trim()) {
            newErrors.landmark = 'Landmark is required';
            isValid = false;
        }
        if (!formData.pincode.trim()) {
            newErrors.pincode = 'Pincode is required';
            isValid = false;
        }
        if (!formData.phone.trim()) {
            newErrors.phone = 'phone is required';
            isValid = false;
        } else if (!/^[\s\d()-]+$/.test(formData.phone)) {
            newErrors.phone = 'Invalid phone format';
            isValid = false;
        }

        // if (!formData.gender) {
        //   newErrors.gender = 'Gender is required';
        //   isValid = false;
        // }

        setErrors(newErrors);
        return isValid;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                const response = await saveAddress(formData);
                if (response) {
                    toast.success("Address saved", { theme: "colored" });
                    fetchAddressData();
                    setErrors({});
                    setToggleForm(false)
                }
            } catch (error) {
                toast.warning("Something went wrong! please try after some time", { theme: "colored" })
            } finally {
            }
        }
    };

    const fetchAddressData = async () => {
        setLoading(true);
        try {
            const response = await getAddress( Number(sessionStorage.getItem('user')) );
            const data = JSON.parse(response.data);
            setAddress(data);
        } catch (error) {

        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    };

    useEffect(() => {
        if (isLoggedIn) {
            fetchCountries();
            fetchAddressData();
        }
    }, []);

    const scrollToId = () => {
        if (targetElementRef.current) {
            targetElementRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    };

    const removeAddress = async (item) => {
        setLoading(true);
        try {
            const response = await updateAddress(item, 1);
            if (response) {
                toast.success("Address deleted", { theme: "colored" });
                window.scrollTo({ top: 0, behavior: 'smooth' });
                fetchAddressData();
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    };

    const makeDefault = async () => {
        setLoading(true);
        try {
            const response = await updateAddress(selectedOption, 2);
            if (response) {
                toast.success("Address set as default", { theme: "colored" });
                window.scrollTo({ top: 0, behavior: 'smooth' });
                closeModal();
                fetchAddressData();
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    };

    const confirmToDelete = (item) => {
        confirmAlert({
            title: 'Confirm Delete',
            message: 'Are you sure you want to delete this item?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        removeAddress(item);
                    },
                },
                {
                    label: 'No',
                    onClick: () => {
                        // Handle the cancelation logic here
                        console.log('Deletion canceled');
                    },
                },
            ],
        });
    }

    const confirmToDefault = (item) => {
        setModalStyle({ display: 'block' })
    }

    const closeModal = () => {
        setModalStyle({ display: 'none' });
    };

    var editAddress = React.useCallback((item) => {
        setFormData(item);
        setSelectedCountry(item.country);
        fetchStates(item.country);
        setSelectedState(item.state);
        fetchCities(item.country, item.state);
        setSelectedCity(item.city);
        setSelectedAddType(item.addressType)
        scrollToId();
        setToggleForm(true)
    }, []);

    return (
        <Auth>
            <div className="row justify-content-center page-layout">
                <div className="col-md-8 pb-5 fixed-height-content">
                    {
                        loading ?
                            <div className="loader-container">
                                <div className="loader">
                                </div>
                            </div> :
                            <div className="row">
                                <Link to="/shop" className="back-btn"><FontAwesomeIcon icon={faArrowLeft} /></Link>
                                {
                                    address.length > 0 ?
                                        <div className='col-md-12'>
                                            <div className="card grid-box-view">
                                                <div className='row'>
                                                    <div className="col-md-6 product-title-text">
                                                        <h3>{address.length || 0} addresses found</h3>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className='row'>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> :
                                        <div></div>
                                }
                                <div className='col-md-4'>
                                    <div className='row'>
                                        <div className='col-md-12 mt-4'>
                                            <button type='button' className='btn btn-light btn-custom-highlight W-100 h-fixed' onClick={toggleAddress}>ADD NEW ADDRESSS</button>
                                        </div>
                                    </div>
                                </div>
                                {
                                    address.length > 0 ?
                                        address.map((item, i) => {
                                            return (
                                                <AddressList {...item} confirmToDelete={confirmToDelete} confirmToDefault={confirmToDefault} editAddress={editAddress} key={i}></AddressList>
                                            )
                                        })
                                        :
                                        <div className="col-md-12 p-4">
                                            <div className="row">
                                                <div className="col-12 col-md-12 col-lg-12 p-0 mt-2">
                                                    <div className="alert alert-warning">
                                                        No address found
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                }
                                {
                                    toggleForm ?
                                        <div className="col-md-12" id="targetElement" ref={targetElementRef}>
                                            <div className='card p-4 mt-4'>
                                                <div className="row">
                                                    <div className="col-12 col-md-12 col-lg-12 mt-2 text-center">
                                                        <form onSubmit={handleSubmit}>
                                                            <div className='row'>
                                                                <div className="col-12 col-md-6 col-lg-6 col-xl-6 form-group">
                                                                    <input type="text" className="form-control" id="name" value={formData.name} onChange={handleChange} placeholder="Your name i.e John doe" name="name" />
                                                                    {errors.name && <span className="invalid-msg">{errors.name}</span>}
                                                                </div>
                                                                <div className="col-12 col-md-6 col-lg-6 col-xl-6 form-group">
                                                                    <input type="number" className="form-control" id="phone" value={formData.phone} onChange={handleChange} placeholder="Your phone i.e 9876543210" name="phone" />
                                                                    {errors.phone && <span className="invalid-msg">{errors.phone}</span>}
                                                                </div>
                                                                <div className="col-12 col-md-6 col-lg-6 col-xl-6 form-group">
                                                                    <input type="text" className="form-control" id="apartmentName" value={formData.apartmentName} onChange={handleChange} placeholder="Apartment name i.e abc apartment" name="apartmentName" />
                                                                    {errors.apartmentName && <span className="invalid-msg">{errors.apartmentName}</span>}
                                                                </div>
                                                                <div className="col-12 col-md-6 col-lg-6 col-xl-6 form-group">
                                                                    <input type="text" className="form-control" id="roomNo" value={formData.roomNo} onChange={handleChange} placeholder="Room Number i.e A101, 101" name="roomNo" />
                                                                    {errors.roomNo && <span className="invalid-msg">{errors.roomNo}</span>}
                                                                </div>
                                                                <div className="col-12 col-md-6 col-lg-6 col-xl-6 form-group">
                                                                    <input type="text" className="form-control" id="address1" value={formData.address1} onChange={handleChange} placeholder="Address 1 i.e ABC nagar" name="address1" />
                                                                    {errors.address1 && <span className="invalid-msg">{errors.address1}</span>}
                                                                </div>
                                                                <div className="col-12 col-md-6 col-lg-6 col-xl-6 form-group">
                                                                    <input type="text" className="form-control" id="address2" value={formData.address2} onChange={handleChange} placeholder="Address 2 i.e ABC road" name="address2" />
                                                                </div>
                                                                <div className="col-6 col-md-6 col-lg-6 col-xl-6 form-group">
                                                                    <select name='country' className="form-control" value={selectedCountry} onChange={handleCountry}>
                                                                        <option value="">-- Please select --</option>
                                                                        {
                                                                            country.map((item, i) => (
                                                                                <option value={item.countryId} key={i}>{item.name}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    {errors.country && <span className="invalid-msg">{errors.country}</span>}
                                                                </div>
                                                                <div className="col-6 col-md-6 col-lg-6 col-xl-6 form-group">
                                                                    <select name='state' className="form-control" value={selectedState} onChange={handleState}>
                                                                        <option value="">-- Please select --</option>
                                                                        {
                                                                            state.map((item, i) => (
                                                                                <option value={item.stateId} key={i}>{item.name}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    {errors.state && <span className="invalid-msg">{errors.state}</span>}
                                                                </div>
                                                                <div className="col-6 col-md-6 col-lg-6 col-xl-6 form-group">
                                                                    <select name='city' className="form-control" value={selectedCity} onChange={handleCity}>
                                                                        <option value="">-- Please select --</option>
                                                                        {
                                                                            city.map((item, i) => (
                                                                                <option value={item.cityId} key={i}>{item.name}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    {errors.city && <span className="invalid-msg">{errors.city}</span>}
                                                                </div>
                                                                <div className="col-12 col-md-6 col-lg-6 col-xl-6 form-group">
                                                                    <input type="text" className="form-control" id="landmark" value={formData.landmark} onChange={handleChange} placeholder="Landmark i.e Near ABC compound" name="landmark" />
                                                                    {errors.landmark && <span className="invalid-msg">{errors.landmark}</span>}
                                                                </div>
                                                                <div className="col-6 col-md-6 col-lg-6 col-xl-6 form-group">
                                                                    <input type="number" className="form-control" id="pincode" value={formData.pincode} onChange={handleChange} placeholder="Landmark i.e 400001" name="pincode" />
                                                                    {errors.pincode && <span className="invalid-msg">{errors.pincode}</span>}
                                                                </div>
                                                                <div className="col-12 col-md-6 col-lg-12 col-xl-12 ml-1">
                                                                    <div className='row'>
                                                                        <div className='col-md-12 pb-3 d-flex'>
                                                                            <label className='label-frm'>Address type :&nbsp;&nbsp;&nbsp;</label>
                                                                            {
                                                                                addType.map((item, i) => (
                                                                                    <label className="form-check-label mb-2" key={i}>
                                                                                        <div className="form-check custom-form-check2">
                                                                                            <input type="radio" className="form-check-input" id={item.name} name='addressType' value={item.name} onChange={handleAddressOptionChange} checked={selectedAddType == item.name}/><span className='bold-text'>{item.name}</span>
                                                                                        </div>
                                                                                    </label>
                                                                                ))
                                                                            }
                                                                            {errors.addressType && <span className="invalid-msg">{errors.addressType}</span>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <button type='submit' className='btn btn-success btn-custom btn-h-40 W-100'>SAVE</button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> :
                                        <div className="col-md-12">

                                        </div>
                                }
                            </div>
                    }
                </div>

                <div className="modal" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" style={modalStyle}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <h4 className='popup-heading'>Select to make default address</h4>
                                    </div>
                                    <div className='col-md-12'>
                                        {
                                            address.map((item, i) => (
                                                <label className="form-check-label custom-radio-btn border-box" key={i}>
                                                    <div className="form-check">
                                                        <input type="radio" className="form-check-input" id={item.addressId} name='defAddress' value={item.addressId} onChange={handleDefaultOptionChange} /><span className='bold-text'>{item.name}</span><br /> {item.roomNo}, {item.apartmentName}, {item.address2}, {item.address2},<br /> <span className='bold-text'>Pincode</span> : {item.pincode}
                                                    </div>
                                                </label>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type='button' className='btn btn-primary btn-h-40 me-2' onClick={() => { makeDefault() }}>SAVE</button>
                                <button type='button' className='btn btn-danger btn-h-40' onClick={() => { closeModal() }}>CANCEL</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Auth>
    )
}


export default UserAddress;
