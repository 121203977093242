import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faTrash, faMap, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';

const ChatBoxList = (props) => {
    var userId = Number(sessionStorage.getItem('user'));
    const tooltipDefault = (
        <Tooltip id="tooltip">
            Click to change default address
        </Tooltip>
    );

    return (
        <div className='col-md-12' key={props.i}>
            <div className="chat-box-view pos-relative">
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='row'>
                            {/* {
                                props.
                            } */}
                            {
                                props.senderId === userId ?
                                    <div className="col-md-12 d-flex justify-content-end chat-bx text-end">
                                        <p className='sender-chat'>{props.senderMsg}</p>
                                        <p className='sender-chat-date'>You</p>
                                    </div> :
                                    <div className="col-md-12 chat-bx text-start">
                                        <p className='text-start'>{props.senderMsg}</p>
                                    </div>
                            }
                            {/* <div className="col-md-12 chat-bx" >
                                <span>{props.toMsg}</span>
                            </div> */}
                            {/* <div className="col-md-12 chat-bx">
                                <span>{props.msg}</span>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default React.memo(ChatBoxList);
