import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

export default function Contact() {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        userId: 0,
        name: '',
        phone: '',
        email: '',
        deletedFlag: 0
    });
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
            isValid = false;
        }

        if (!formData.phone.trim()) {
            newErrors.phone = 'phone is required';
            isValid = false;
        } else if (!/^[\s\d()-]+$/.test(formData.phone)) {
            newErrors.phone = 'Invalid phone format';
            isValid = false;
        }
        if (!formData.email) {
            newErrors.email = 'email is required';
            isValid = false;
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            newErrors.email = 'Invalid email format';
            isValid = false;
        }
        setErrors(newErrors);
        return isValid;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();
        if (validateForm()) {
            try {
                // await saveDataToGoogleSheets();
            } catch (error) {
                // toast.warning("Something went wrong! please try after some time", { theme: "colored" })
            } finally {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            }
        }
    };

    const saveDataToGoogleSheets = async () => {
        try {
            // Replace 'YOUR_GOOGLE_SHEETS_API_KEY' and 'YOUR_SPREADSHEET_ID' with your own values
            const apiKey = '9265a590f0123660efa9dd228441a69e1643c551';
            const spreadsheetId = '1c3zI6IJJv-O2sV3j-AwoAq9weolQqhAk0AN4CNUU0vA';

            // Google Sheets API endpoint
            const sheetsEndpoint = `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/Sheet1:append?valueInputOption=USER_ENTERED&key=${apiKey}`;

            // Prepare data to be sent to Google Sheets
            const values = Object.values(formData);
            const rowData = [values];

            // Make a POST request to Google Sheets API
            await axios.post(sheetsEndpoint, {
                values: rowData,
            });

            console.log('Data saved to Google Sheets successfully!');
        } catch (error) {
            console.error('Error saving data to Google Sheets:', error);
        }
    };

    useEffect(() => {
    }, []);

    return (
        <div className="row justify-content-center page-layout">
            <div className='col-md-12 text-center'>
                <h1>Drop a message</h1>
            </div>
            <div className="col-md-6">
                <div className='card p-4 mt-4'>
                    {
                        loading ?
                            <div className="loader-container">
                                <div className="loader">
                                </div>
                            </div> :
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 mt-2 text-center">
                                    <form onSubmit={handleSubmit}>
                                        <div className='row'>
                                            <div className="col-12 col-md-12 col-lg-12 col-xl-12 form-group">
                                                <input type="text" className="form-control" id="name" value={formData.name} onChange={handleChange} placeholder="Your name i.e John doe" name="name" />
                                                {errors.name && <span className="invalid-msg">{errors.name}</span>}
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12 col-xl-12 form-group">
                                                <input type="number" className="form-control" id="phone" value={formData.phone} onChange={handleChange} placeholder="Your phone i.e 9876543210" name="phone" />
                                                {errors.phone && <span className="invalid-msg">{errors.phone}</span>}
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-12 col-xl-12 form-group">
                                                <input type="email" className="form-control" id="email" value={formData.email} onChange={handleChange} placeholder="Your email i.e abc@gmail.com" name="email" />
                                                {errors.email && <span className="invalid-msg">{errors.email}</span>}
                                            </div>
                                        </div>
                                        <button type='submit' className='btn btn-success btn-custom btn-h-40 W-100'>SEND ENQUIRY</button>
                                    </form>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

