import React, { useState, useEffect } from "react";
import BannerAdv from "./comman/BannerAdv";
import Sidenav from "./comman/Sidenav";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ShopList from "./ShopList";
import { useDispatch } from 'react-redux';
import { addToCart, incrementQuantity, decrementQuantity, updateItem, removeFromCart, clearCart} from "../redux/actions/counterActions";
import { getProducts, searchProduct } from '../api-services/api';

function Shop(props) {
    const dispatch = useDispatch();
    const [products, setProducts] = useState([]);
    const [apiData, setApiData] = useState([]);
    const [loading, setLoading] = useState(true);
    const fetchData = async () => {
        try {
          const response = await getProducts();
          const data = JSON.parse(response.data);
          setApiData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      };

      useEffect(() => {
        fetchData();
      }, []);

    var getDiscountedPrice = React.useCallback((p, d) => {
        var price = Number(p);
        var discount = parseInt(d, 10);
        var totalPrice;
        totalPrice = price * ((100 - discount) / 100);
        return totalPrice.toFixed(2);
    }, []);

    var addToCartFn = React.useCallback((item, index) => {
        var temp = [...apiData];
        temp[index].status = !temp[index].status;
        setProducts([...temp]);
        toast.success("Item added to cart", { theme: "colored" });
        dispatch(addToCart(item));
    }, [products, setProducts]);

    var removeFromCartFn = React.useCallback((item, index) => {
        var temp = [...apiData];
        temp[index].status = !temp[index].status;
        setProducts([...temp]);
        toast.success("Item removed from cart", { theme: "colored" });
        dispatch(updateItem(item));
    }, [products, setProducts]);

    var searchData = async (selectedItem) => {
        setLoading(true);
        try {
            const response = await searchProduct(selectedItem);
            const data = JSON.parse(response.data);
            setApiData(data);
          } catch (error) {
            console.error('Error fetching data:', error);
          } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
          }
    };

    return (
        <div className="row justify-content-center">
            <div className="col-md-12 pt-0 pb-5">

                <div className="row">
                    <div className="col-md-12 p-0">
                        <BannerAdv></BannerAdv>
                    </div>
                    <div className="col-md-3">
                        <div className="row sticky-sidenav">
                            <Sidenav searchData={searchData}></Sidenav>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="product-block">
                            <div className="row">
                                {
                                    loading ?
                                        <div className="loader-container">
                                            <div className="loader">
                                            </div>
                                        </div> :
                                        apiData.map((todo, i) => {
                                            return <ShopList {...todo} i={i} key={i} addToCartFn={addToCartFn} removeFromCartFn={removeFromCartFn} getDiscountedPrice={getDiscountedPrice}></ShopList>
                                        })

                                }

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}


export default Shop;