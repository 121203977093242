import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import CartBox from './cartBox';
import { addToCart, incrementQuantity, decrementQuantity, updateItem, removeFromCart, clearCart } from "../../redux/actions/counterActions";
import { getOrders, getOrdersWithPagination } from '../../api-services/api';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import jsPDF from 'jspdf';
import Auth from '../../api-services/auth';
import { login, logout, selectIsLoggedIn, selectUser } from '../../redux/slices/authenticationSlice';
import DataTable from '../../resources/Datatable';
import { Pagination } from 'antd';
// import 'antd/dist/antd.css';

const Orders = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = React.useState([]);
    const [selectedRows, setSelectedRows] = React.useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(8);
    const [totalItems, setTotalItems] = useState(0);

    const fetchOrders = async (curPage, pgSize) => {
        // setLoading(true);
        try {
            // const response = await getOrders(Number(sessionStorage.getItem('user')));
            const response = await getOrdersWithPagination({ id: Number(sessionStorage.getItem('user')), page: curPage || currentPage, size: pgSize || pageSize });
            if (response) {
                const data = response.data.data;
                setOrders(data);
                setTotalItems(response.data.total)
            }
        } catch (error) {
            console.log(error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    };

    const printDateAndTime = (date) => {
        const fullDate = new Date(date);
        const options = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true, // Use 24-hour format
            timeZone: 'Asia/Kolkata' // Specify the time zone if needed
        };
        const formattedDateTime = fullDate.toLocaleString('en-US', options);
        return formattedDateTime;
    };

    const generatePDF = (order, orderItems) => {
        // Create a new jsPDF instance
        const pdfDoc = new jsPDF();
        // Add content to the PDF
        pdfDoc.setFont('helvetica', 'normal');
        pdfDoc.setFontSize(18);
        pdfDoc.text('Invoice', 20, 20);

        // Invoice details
        pdfDoc.setFontSize(12);
        pdfDoc.text('Invoice Date: ' + printDateAndTime(new Date()), 20, 30);
        pdfDoc.text('Order Date: ' + printDateAndTime(order.orderDate), 20, 40);

        // Customer details
        pdfDoc.text('Customer: ' + order.user.name, 20, 50);
        pdfDoc.text('Address: ' + order.address?.roomNo + ' ' + order.address?.apartmentName + ' ' + order.address?.address1, 20, 60);
        pdfDoc.text('' + order.address?.address2 + ' Near ' + order.address?.landmark, 38, 67);
        pdfDoc.text('' + order.address?.cityName + ' - ' + order.address?.pincode, 38, 74);

        // Table header
        pdfDoc.setLineWidth(0.5);
        pdfDoc.line(20, 80, 190, 80);
        pdfDoc.setFont('helvetica', 'bold');
        pdfDoc.text('#Item', 20, 85);
        pdfDoc.text('Product', 40, 85);
        pdfDoc.text('Price', 100, 85);
        pdfDoc.text('Quantity', 130, 85);
        pdfDoc.text('Total', 160, 85);
        pdfDoc.line(20, 90, 190, 90);

        let yPosition = 100;
        // Iterate through items and add them to the PDF
        orderItems.forEach((item, i) => {
            pdfDoc.setFont('helvetica', 'normal');
            pdfDoc.text(String(i + 1), 20, yPosition);
            pdfDoc.text(pdfDoc.splitTextToSize(item.name + ' - ' + item.category, 50), 40, yPosition);
            pdfDoc.text('Rs ' + item.discountPrice.toFixed(2), 100, yPosition);
            pdfDoc.text(String(item.quantity), 130, yPosition);
            pdfDoc.text('Rs ' + (item.quantity * item.discountPrice).toFixed(2), 160, yPosition);
            yPosition += 15;
        });

        // Total
        pdfDoc.setFont('helvetica', 'bold');
        pdfDoc.text('Total:', 130, yPosition + 10);
        pdfDoc.text('Rs ' + orderItems.reduce((total, item) => total + item.quantity * item.discountPrice, 0).toFixed(2), 160, yPosition + 10);

        // Save the PDF or trigger a download
        pdfDoc.save(order.orderId + '.pdf');
    };

    useEffect(() => {
        if (isLoggedIn) {
            fetchOrders();
        }
    }, []);

    var selectAllData = React.useCallback((type, data, e) => {
        if (e.target.checked) {
            setSelectedRows((prevRows) => [...prevRows, data]);
        } else {
            setSelectedRows((prevRows) => prevRows.filter((row) => row.id !== data.id));
        }
    }, [selectedRows, setSelectedRows]);


    var handleOnChange = React.useCallback(() => {
    }, [selectedRows]);

    const columns = [
        {
            Header: 'Index Id',
            accessor: 'indexId',
        },
        {
            Header: 'Order Id',
            accessor: 'orderId',
        },
        {
            Header: 'Order Token Id',
            accessor: 'orderTokenId',
        },
        {
            Header: 'Completion',
            accessor: 'completion',
            Cell: ({ row }) => {
                return <div className="progress blue">
                    <span className="progress-left">
                        <span className="progress-bar"></span>
                    </span>
                    <span className="progress-right">
                        <span className="progress-bar"></span>
                    </span>
                    <div className="progress-value">{row.original.indexId}%</div>
                </div>;
            }
        },
        {
            Header: 'Total Price',
            accessor: 'totalPrice',
        },
        {
            Header: 'Status',
            accessor: 'derivedValue', // You can name it whatever you want
            Cell: ({ row }) => {
                // const derivedValue = row.original.indexId;
                return <input type='checkbox' name='check' id='check'></input>
                    ;
            }
        }
    ];

    const handlePageChange = React.useCallback((page, pgSize) => {
        setCurrentPage(page);
        setPageSize(pgSize);
        fetchOrders(page, pgSize);
    }, [pageSize, currentPage]);

    return (
        <Auth>
            <div className="row page-layout">
                <div className="col-md-12">
                    <div className="row justify-content-center">
                        <div className="col-md-7 text-start pb-4">
                            <Link to="/shop" className="back-btn2"><FontAwesomeIcon icon={faArrowLeft} /></Link>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-7 pb-5">
                            <div className="row">
                                <div className="col-md-12 product-title-text pb-4"><h2>My Orders</h2></div>
                            </div>
                            {/* <div className="row">
                                <div className='col-md-10 text-start pb-4 ps-0'>
                                    <Pagination
                                        showSizeChanger
                                        current={currentPage}
                                        pageSize={pageSize}
                                        total={totalItems}
                                        // showQuickJumper
                                        // showTotal={(total) => `Total ${total} items`}
                                        onChange={handlePageChange}
                                        className='paginate-without-size-changer'
                                    />
                                </div>
                                <div className='col-md-2 text-end pb-4 pe-0'>
                                    <button className="btn btn-warning w-100 h-100" onClick={handleOnChange}>GET DATA</button>
                                </div>
                                <div className='col-md-12'>
                                    {
                                        loading ?
                                            <div className="loader-container">
                                                <div className="loader">
                                                </div>
                                            </div> :
                                            <DataTable columns={columns} data={orders} selectAllData={selectAllData} pageSize={pageSize} />
                                    }
                                </div>
                                <div className='col-md-12 text-end pt-3 pe-0'>
                                    <Pagination
                                        // showSizeChanger
                                        current={currentPage}
                                        pageSize={pageSize}
                                        total={totalItems}
                                        // showQuickJumper
                                        // showTotal={(total) => `Total ${total} items`}
                                        onChange={handlePageChange}
                                    />
                                </div>
                            </div> */}


                            <div className="row">
                                {
                                    loading ?
                                        <div className="loader-container">
                                            <div className="loader">
                                            </div>
                                        </div> :
                                        <div className="row">
                                            <div className='col-md-12 text-start pb-4'>
                                                <Pagination
                                                    showSizeChanger
                                                    current={currentPage}
                                                    pageSize={pageSize}
                                                    total={totalItems}
                                                    // showQuickJumper
                                                    // showTotal={(total) => `Total ${total} items`}
                                                    onChange={handlePageChange}
                                                    className='paginate-without-size-changer'
                                                />
                                            </div>
                                            <div className="col-md-12">

                                                <div className="accordion order-accordian" id="accordionExample">
                                                    {
                                                        orders.map((item, i) => (
                                                            <div className="accordion-item" key={i}>
                                                                <h2 className="accordion-header" id={`heading-${item.orderId}`}>
                                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${item.orderId}`} aria-controls={`collapse-${item.orderId}`}>
                                                                        <p>ORDER ID- {item.orderId}</p><br />
                                                                        <p className='ord-date'>ORDER DATE- {printDateAndTime(item.orderDate) || '-'}</p>
                                                                    </button>
                                                                </h2>
                                                                <div id={`collapse-${item.orderId}`} className="accordion-collapse collapse" aria-labelledby={`heading-${item.orderId}`} data-bs-parent="#accordionExample">
                                                                    <div className="accordion-body">
                                                                        <div className="col-md-12">
                                                                            <ul className="list-unstyled order-list-item">
                                                                                <li>ORDER ID <span>{item.orderId}</span></li>
                                                                                <li>CUSTOMER <span>{item.user.name}</span></li>
                                                                                <li>CONTACT <span>{item.user.phone}</span></li>
                                                                                <li>DISCOUNT <span>-</span></li>
                                                                                <li>ORDER DATE <span>{printDateAndTime(item.orderDate) || '-'}</span></li>
                                                                                <li>ORDER TOTAL <span className="total-paid">PAID {item.totalPrice}</span></li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="col-md-12 product-title-text"><h3>Items</h3></div>
                                                                        <div className="col-md-12">
                                                                            <div className='row'>
                                                                                {
                                                                                    item.items.map((item2, j) => (
                                                                                        <div className='col-md-8' key={j}>
                                                                                            <div className="card grid-box-view grid-box-view2">
                                                                                                <div className='row'>
                                                                                                    <div className='col-md-2 flex-layout flex-layout2'>
                                                                                                        <img src={item2.imageUrl
                                                                                                        } className='img-fluid' alt='' />
                                                                                                    </div>
                                                                                                    <div className='col-md-6'>
                                                                                                        <div className='row'>
                                                                                                            <div className="col-md-12 product-title">
                                                                                                                <h6>{item2.name}</h6>
                                                                                                            </div>
                                                                                                            <div className="col-md-12 product-pricing-content">
                                                                                                                <h6 className='color1'> ₹{item2.discountPrice}</h6>
                                                                                                            </div>
                                                                                                            <div className="col-md-12 title-gradeout">
                                                                                                                <h6>QTY - {item2.quantity}</h6>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-md-4">
                                                                                                        <div className='row'>
                                                                                                            <div className="col-md-12 product-pricing-content product-pricing-flex product-pricing-flex2">
                                                                                                                <div className='product-pricing-discounted'>
                                                                                                                    <h6 className='color1'> ₹{item2.discountPrice * item2.quantity}</h6>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12 pt-4">
                                                                            <button className='btn btn-warning btn-theme4 w-25' type='button' onClick={() => { generatePDF(item, item.items) }}>Download Reciept</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-md-12 text-end pt-3'>
                                                <Pagination
                                                    // showSizeChanger
                                                    current={currentPage}
                                                    pageSize={pageSize}
                                                    total={totalItems}
                                                    // showQuickJumper
                                                    // showTotal={(total) => `Total ${total} items`}
                                                    onChange={handlePageChange}
                                                />
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Auth>
    )

}


export default Orders;
