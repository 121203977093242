import React from "react";

export default function BannerAdv() {
    var image = { _id: 1, name: 'Get Offers', imageUrl: '../assets/images/banner1.png'}

    return (
        <div className="container-fluid p-0 adv">
            <img src={image.imageUrl} alt={image.name} className="img-fluid"/>
        </div>
    )
}

