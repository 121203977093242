import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faUser, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { login, logout, selectIsLoggedIn, selectUser } from '../../redux/slices/authenticationSlice';
import { useSelector, useDispatch } from 'react-redux';

export default function Navbar() {
  const history = useHistory();
  const items = useSelector(state => state);
  var userId = Number(sessionStorage.getItem('user'));

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  
  const handleLogout = () => {
    dispatch(logout());
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('isAuthenticated');
    toast.success("Logged out", { theme: "colored" });
    history.push('/shop');
  };

  return (
    <nav className="navbar navbar-expand-sm sticky-nav">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/home">
          <img src="../assets/images/logo.png" className="img-fluid" alt="" />
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="collapsibleNavbar">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to="/home" className="nav-link">Home</Link>
            </li>
            <li className="nav-item">
              <Link to="/about" className="nav-link">About</Link>
            </li>
            <li className="nav-item">
              <Link to="/services" className="nav-link">Services</Link>
            </li>
            <li className="nav-item">
              <Link to="/products" className="nav-link">Products</Link>
            </li>
            <li className="nav-item">
              <Link to="/contact" className="nav-link">Contact</Link>
            </li>
            <li className="nav-item">
              <Link to="/shop" className="nav-link active">Shop</Link>
            </li>
          </ul>
          <ul className="navbar-nav cart">
            <div className="count-box">{items.cart.length}</div>
            <li className="nav-item" >
              <Link to="/viewCart" className="nav-link">
                <FontAwesomeIcon icon={faCartShopping} />
              </Link>
            </li>
            <li className="nav-item dropdown pr-0">
              <span className="nav-link dropdown-toggle profile-toggler" data-bs-toggle="dropdown"><FontAwesomeIcon icon={faUser} /></span>
              {
                isLoggedIn > 0 ?
                  <ul className="dropdown-menu">
                    <Link to={`/profile`} className="dropdown-item"><FontAwesomeIcon icon={faUser} /> My Account</Link>
                    <Link to="/viewAddress" className="dropdown-item"><FontAwesomeIcon icon={faLocationDot} /> My Addresses</Link>
                    <Link to="/orders" className="dropdown-item"><FontAwesomeIcon icon={faCartShopping} /> My Orders</Link>
                    <span className="dropdown-item" onClick={() => { handleLogout() }}><FontAwesomeIcon icon={faLocationDot}/> Logout</span>
                  </ul> : 
                  <ul className="dropdown-menu">
                  <Link to={`/register`} className="dropdown-item"><FontAwesomeIcon icon={faUser} /> Register</Link>
                  <Link to="/login" className="dropdown-item"><FontAwesomeIcon icon={faLocationDot} /> Login</Link>
                </ul> 
              }
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

