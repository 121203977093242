import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faTrash, faMap, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';

const AddressList = (props) => {
    
    const tooltipDefault = (
        <Tooltip id="tooltip">
            Click to change default address
        </Tooltip>
    );

    return (
        <div className='col-md-4' key={props.i}>
            <div className="card grid-box-view pos-relative">
                <div className='row'>
                    { 
                    props.isDefault ?
                    <OverlayTrigger placement="top" overlay={tooltipDefault}>
                        <div className='default-icon' onClick={() => { props.confirmToDefault(props, props.i) }}><FontAwesomeIcon icon={faLocationDot} />Change default  </div>
                    </OverlayTrigger> : 
                    null
                   }
                    <div className='col-md-12'>
                        <div className='row'>
                            <div className="col-md-12 address-line pe-4">
                                <p className='mb-0'>
                                    <span className='bold-text'>{props?.name}</span> <br />
                                    {props?.roomNo}, {props?.apartmentName}, <br />
                                    {props?.address1}, {props?.address2} <br />
                                    Near {props?.landmark}, <br />
                                    {props?.pincode}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='btn-bottom'>
                        <div className='row'>
                            <div className='col-md-6 btn-align-center btn-stl1' onClick={() => { props.editAddress(props, props.i) }}>
                                EDIT
                            </div>
                            <div className='col-md-6 btn-align-center btn-stl2' onClick={() => { props.confirmToDelete(props, props.i) }}>
                                REMOVE
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>

    )
}


export default AddressList;
