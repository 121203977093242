// authenticationSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: false,
  user: null,
};

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
});

export const { login, logout } = authenticationSlice.actions;
export const selectIsLoggedIn = (state) => state.authentication.isLoggedIn;
export const selectUser = (state) => state.authentication.user;

export default authenticationSlice.reducer;