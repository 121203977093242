import { ADDTOCART, REMOVEITEM, UPDATEITEM, CLEARCART, INCREMENTQTY, DECREMENTQTY } from "../actions/counterActions";

const cartReducer = (state = [], action) => {
    switch (action.type) {
        case ADDTOCART:
            const itemInCart = state.find((item) => item.id === action.payload.id);
            if (itemInCart) {
                itemInCart.quantity++;
            } else {
                return [...state, { ...action.payload, quantity: 1 }];
            }
            break;

        case REMOVEITEM:
            return state.filter((item) => item.id !== action.payload.id);

        case UPDATEITEM:
            if (Object.keys(action.payload).length === 0) {
                return [];
            } else {
                const index = state.findIndex(item => item.id === action.payload.id);
                return state.filter((_, i) => i !== index);
            }
            break;

        case CLEARCART:
            return [];
            break;

        case INCREMENTQTY:
            return state.map(item =>
                item.id === action.payload.id
                    ? { ...item, quantity: item.quantity + 1 }
                    : item
            );
            break;

        case DECREMENTQTY:
            return state.map(item =>
                item.id === action.payload.id
                    ? { ...item, quantity: Math.max(1, item.quantity - 1) }
                    : item
            );
            break;

        default:
            return state;
    }
};

export { cartReducer };
