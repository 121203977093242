// src/services/api.js
import axios from 'axios';

// const API_BASE_URL = 'https://reactapp.webscaler.in/be/api';
const API_BASE_URL = 'http://localhost:3002/api';
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

const apiFile = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
  }
});

// Define your API requests here
export const getProducts = () => {
  return api.get('/products');
};

export const getProductById = (id) => {
  return api.get(`/getProductById/${id}`);
};

export const searchProduct = (data) => {
  return api.post(`/filterProductById`,data);
};

export const saveAddress = (data) => {
  return api.post(`/saveAddress`,data);
};

export const updateAddress = (data, id) => {
  return api.put(`/updateAddress/${id}`,data);
};

export const getAddress = (id) => {
  return api.get(`/getAddress/${id}`);
};

export const saveProfile = (data) => {
  return api.post(`/saveProfile`,data);
};

export const saveProfilePhoto = (data) => {
  return apiFile.post(`/saveProfilePhoto`,data);
};

export const getProfile = (id) => {
  return api.get(`/getProfile/${id}`);
};

export const checkProfile = (data) => {
  return api.post(`/checkProfile/:chk_login`,data);
};

export const getCountry = (id) => {
  return api.get(`/countries`);
};

export const getState = (CountryId) => {
  return api.get(`/states/${CountryId}`);
};

export const getCity = (CountryId, StateId) => {
  return api.get(`/cities/${CountryId}/${StateId}`);
};

export const placeOrder = (data) => {
  return api.post(`/placeOrder`,data);
};

export const getOrderDetails = (data) => {
  return api.post(`/getOrderDetails/${data.orderTokenId}`,data);
};

export const getOrders = (id) => {
  return api.get(`/getOrders/${id}`);
};

export const getOrdersWithPagination = (data) => {
  return api.post(`/getOrdersWithPagination/${data.id}/${data.page}/${data.size}`,data);
};

export const sendMessage = (data) => {
  return api.post(`/sendMessage`,data);
};

export const getMessage = (data) => {
  return api.put(`/getMessage`,data);
};

export const getAllUsers = (userId) => {
  // return api.get(`/getAllUsers/${userId}`);
  return api.get(`/getAllUsers/ext_current/${userId}`);
};

// Add more API endpoints as needed

export default api;