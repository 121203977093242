import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter
} from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Services from "./components/Services";
import Product from "./components/Products/Product";
import Contact from "./components/Contact";
import Mobile from "./components/Products/Mobile";
import Laptop from "./components/Products/Laptop";
import Ac from "./components/Products/Ac";
import Consultation from "./components/Services/Consultation";
import Training from "./components/Services/Training";
import Seodm from "./components/Services/Seodm";
import ProductDetails from "./components/ProductDetails";
import UserAddress from "./components/address/UserAddress";
import Shop from "./components/Shop";
import Navbar from "./components/comman/Navbar";
import { ToastContainer } from 'react-toastify';
import ViewCart from "./components/ViewCart";
import Profile from "./components/profile/profile";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import Checkout from "./components/checkouts/checkout";
import OrderStatus from "./components/checkouts/orderStatus";
import Orders from "./components/checkouts/orders";
import ChatBox from "./components/communication/chat";

function App() {
  const [showChatBox, setShowChatBox] = useState(false);
  const handleChange = () => {
      var ctBox = !showChatBox
      setShowChatBox(ctBox)
   };
  return (
    <div className="container-fluid">
      <BrowserRouter>
        <Navbar></Navbar>
        <Switch>
          <Route path="/" exact={true}><Home></Home></Route>
          <Route path="/home"><Home></Home></Route>
          <Route path="/about"><About></About></Route>
          <Route path="/services"><Services></Services></Route>
          <Route path="/consultation"><Consultation></Consultation></Route>
          <Route path="/training"><Training></Training></Route>
          <Route path="/SEODM"><Seodm></Seodm></Route>
          <Route path="/products"><Product></Product></Route>
          <Route path="/mobile"><Mobile></Mobile></Route>
          <Route path="/laptop"><Laptop></Laptop></Route>
          <Route path="/ac"><Ac></Ac></Route>
          <Route path="/contact"><Contact></Contact></Route>
          <Route path="/shop"> <Shop></Shop></Route>
          <Route path="/viewCart"> <ViewCart></ViewCart></Route>
          <Route path="/viewAddress"><UserAddress></UserAddress></Route>
          <Route path="/productDetails/:id"> <ProductDetails></ProductDetails></Route>
          <Route path="/profile"> <Profile></Profile></Route>
          <Route path="/checkout"> <Checkout></Checkout></Route>
          <Route path="/ord_staus/:orderTokenId"> <OrderStatus></OrderStatus></Route>
          <Route path="/orders"> <Orders></Orders></Route>
          <Route path="/register"><Register></Register></Route>
          <Route path="/home"><Home></Home></Route>
          <Route path="/login"><Login></Login></Route>
          <Route path="/" exact={true}><Home></Home></Route>
        </Switch>
        <ChatBox/>
      </BrowserRouter>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>


  );
}

export default App;
