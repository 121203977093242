import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { addToCart, incrementQuantity, decrementQuantity, updateItem, removeFromCart, clearCart} from "../redux/actions/counterActions";
import { useSelector, useDispatch} from 'react-redux';
import { getProductById } from '../api-services/api';

const ProductDetails = () => {
    const dispatch = useDispatch();
    const items = useSelector(state => state.cart);
    let [quantity, setQuantity] = React.useState(1);
    const params = useParams();
    const [product, setProduct] = React.useState();
    const [enableBtn, setDisableBtn] = React.useState(false);
    const [loading, setLoading] = useState(true);
    const [inputValue, setInputValue] = useState(1);
    const [totalValue, setTotalValue] = useState();
    const handleInputChange = (event) => {
      setInputValue(event.target.value);
    };
    const fetchData = async () => {
        setLoading(true)
        try {
          const response = await getProductById(params.id);
          const data = response.data;
          setProduct(data);
          const itemInCart = items.find((item) => item.id === Number(params.id));
          if(itemInCart)
          {
            setDisableBtn(true)
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
      };

      useEffect(() => {
        fetchData();
      }, [params.id]);

    var addToCartFn = React.useCallback((item2,i) => {
        // var temp = [...products];
        // const index = temp.findIndex(item => item.id === item2.id);
        item2.status = !item2.status;
        // setProducts([...temp]);
        setProduct(item2);
        toast.success("Item added to cart", { theme: "colored" });
        dispatch(addToCart(item2));
        setDisableBtn(true);
    }, []);

    var removeFromCartFn = React.useCallback((item2,i) => {
        // var temp = [...products];
        // const index = temp.findIndex(item => item.id === item2.id);
        item2.status = !item2.status;
        // setProducts([...temp]);
        setProduct(item2);
        toast.success("Item removed from cart", { theme: "colored" });
        dispatch(updateItem(item2));
        setDisableBtn(false)
    }, []);

    function getSubTotal() {
        var subTotalPrice;
        subTotalPrice = product?.discountPrice * product?.quantity;
        return subTotalPrice;
    };

    var incQuantity = React.useCallback((item2,i) => {
        // const updatedProduct = { ...product, quantity: item2.quantity + 1 };
        // setProduct(updatedProduct);
        setProduct((product) => ({
            ...product,
            quantity: item2.quantity + 1,
          }));
        setInputValue(item2.quantity + 1);
        // console.log(product);
        getSubTotal();
    }, []);

    var decQuantity = React.useCallback((item2,i) => {
        // console.log(item2);
        // const updatedProduct = { ...product, quantity: item2.quantity > 1 ? item2.quantity - 1 : 1 };
        // setProduct(updatedProduct);
        setProduct((product) => ({
            ...product,
            quantity: item2.quantity > 1 ? item2.quantity - 1 : 1,
          }));
        setInputValue(item2.quantity > 1 ? item2.quantity - 1 : 1);
        // console.log(updatedProduct);
        getSubTotal();
    }, []);

    // function incQuantity(){
    //     var qt = quantity + 1;
    //     setQuantity(qt);
    //  }
 
    //  function decQuantity(){
    //      var qt;
    //      qt = quantity > 1 ? quantity - 1 : 1
    //      setQuantity(qt)
    //   }

    return (
        <div className="row justify-content-center page-layout">
            <div className="col-md-10">

                {
                    loading ?
                    <div className="loader-container">
                        <div className="loader">
                        </div>
                    </div> : 
                    <div className="row">
                    <Link to="/shop" className="back-btn"><FontAwesomeIcon icon={faArrowLeft} /></Link>
                    <div className='col-md-5'>
                        <div className="card grid-box-view-picture">
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className='zoom-container'>
                                        <img src={product?.imageUrl} className='img-fluid' alt='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-7'>
                        <div className="card grid-box-view">
                            <div className='row'>
                                <div className="col-md-12 title-gradeout">
                                    <h5>{product?.category}</h5>
                                </div>
                                <div className="col-md-12 product-title">
                                    <h3>{product?.name}</h3>
                                </div>
                                <div className="col-md-12 rated-marks">
                                    <div className='marks'>
                                        <div className="rate">
                                            <input type="radio" id="star5" name="rate" value="5" />
                                            <label htmlFor="star5" title="text">5 stars</label>
                                            <input type="radio" id="star4" name="rate" value="4" />
                                            <label htmlFor="star4" title="text">4 stars</label>
                                            <input type="radio" id="star3" name="rate" value="3" />
                                            <label htmlFor="star3" title="text">3 stars</label>
                                            <input type="radio" id="star2" name="rate" value="2" />
                                            <label htmlFor="star2" title="text">2 stars</label>
                                            <input type="radio" id="star1" name="rate" value="1" />
                                            <label htmlFor="star1" title="text">1 star</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 product-pricing">
                                    <h1> ₹{product?.discountPrice} &nbsp;<span className='text-decoration-line-through color1'>₹{product?.price}</span>&nbsp;&nbsp;<span className="badge bg-secondary badge-percent">{product?.discount}</span></h1>
                                </div>
                                <div className="col-md-3 product-pricing">
                                        <div className="input-group customize-input-group mb-3">
                                            <span className="input-group-text" onClick={() => {decQuantity(product)}}><FontAwesomeIcon icon={faMinus} /></span>
                                            <input type="text" className="form-control" placeholder="Username" name="usrname" value={inputValue} onChange={handleInputChange} disabled/>
                                            <span className="input-group-text" onClick={() => {incQuantity(product)}}><FontAwesomeIcon icon={faPlus} /></span>
                                        </div>
                                </div>
                                <div className="col-md-12 product-desc">
                                    <p>{product?.desc}</p>
                                </div>
                                <div className="col-md-12 pt-3">
                                    <div className='row'>
                                        <div className="col-md-6 text-start">
                                            {
                                                enableBtn ?
                                                    <button className="btn btn-warning btn-theme3" onClick={() => { removeFromCartFn(product) }}>Remove</button> :
                                                    <button className="btn btn-warning btn-theme3" onClick={() => { addToCartFn(product) }}>Add to cart</button>
                                            }
                                        </div>
                                        <div className="col-md-6 text-end">
                                            <button className="btn btn-danger btn-theme3">Buy Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card grid-box-view">
                        <div className='row'>
                                <div className="col-md-12 title-gradeout">
                                     <div className="row">
                                        <div className="col-md-6 text-left">
                                        <h1> Total Price </h1>
                                        </div>
                                        <div className="col-md-6 text-end">
                                          <h1 className="pos-relative">&nbsp;<span className='color1 font-bold-600'>₹{getSubTotal()}</span><br/>
                                          <i className="font-sz-1">(All taxes included)</i> </h1>
                                        </div>
                                     </div>
                                </div>
                        </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    )
}

export default ProductDetails;
