import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { checkProfile } from '../../api-services/api';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { login, logout, selectIsLoggedIn, selectUser } from '../../redux/slices/authenticationSlice';

const Login = () => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const user = useSelector(selectUser);
    const dispatch = useDispatch();

    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [isExistError, setExistError] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};
        if (!formData.password) {
            newErrors.password = 'Password is required';
            isValid = false;
        } else if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(formData.password)) {
            newErrors.password = 'Invalid password format';
            isValid = false;
        }
        if (!formData.email) {
            newErrors.email = 'email is required';
            isValid = false;
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            newErrors.email = 'Invalid email format';
            isValid = false;
        }
        setErrors(newErrors);
        return isValid;
    };

    const handleLogin = (data) => {
        dispatch(login(data));
      };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                const response = await checkProfile(formData);
                if (response) {
                    if (response.data.type === true) {
                        setExistError(false)
                        toast.success("Logged in", { theme: "colored" });
                        sessionStorage.setItem('user', response.data.data.userId);
                        handleLogin(response.data.data);
                        history.push('/profile');
                        setErrors({});
                    }
                    else {
                        setExistError(true)
                    }
                }
            } catch (error) {
                // toast.warning("Something went wrong! please try after some time", { theme: "colored" })
            } finally {
            }
        }
    };

    return (
        <div className="row justify-content-center page-layout">
            <div className="col-md-4">
                <div className='card p-4 mt-4'>
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12 mt-2 text-center">
                            <div className='row'>
                                <div className='col-md-12 text-center'>
                                    <h1 className='block-heading'>LOGIN</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12 mt-2 text-center">
                            <form onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        {
                                            isExistError ?
                                                <div className='alert alert-danger'>
                                                    User Not exist
                                                </div> :
                                                null
                                        }
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-12 form-group">
                                        <input type="email" className="form-control" id="email" value={formData.email} onChange={handleChange} placeholder="Your email i.e abc@gmail.com" name="email" />
                                        {errors.email && <span className="invalid-msg">{errors.email}</span>}
                                    </div>
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-12 form-group">
                                        <input type="password" className="form-control" id="password" value={formData.password} onChange={handleChange} placeholder="Your password" name="password" />
                                        {errors.password && <span className="invalid-msg">{errors.password}</span>}
                                    </div>
                                </div>
                                <button type='submit' className='btn btn-success btn-custom btn-h-40 W-100'>LOGIN</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Login;
