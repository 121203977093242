import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsLoggedIn } from '../redux/slices/authenticationSlice';
import { useHistory } from 'react-router-dom';

const Auth = ({ children }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!isLoggedIn) {
      console.log('User not logged in. Redirecting to login page.');
      history.push('/login');
    }
  }, [isLoggedIn, history]);

  // Render the children if the user is logged in, or null if not.
  return isLoggedIn ? <>{children}</> : null;
};

export default Auth;
