import React from "react";

export default function Consultation() {
    return (
        <div className="row justify-content-center">
            <div className="col-md-12 card-box">
                <div className="card-body">
                    <p>The meaning of CONSULTATION is council, conference; specifically : a deliberation between physicians on a case or its treatment. How to use consultation in ...</p>
                </div>
            </div>
        </div>
    )
}

