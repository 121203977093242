import React, { useState, useEffect } from "react";

export default function Sidenav(props) {
    const [inputValue, setInputValue] = useState([]); // Initially undefined
    const [selectedCatItems, setSelectedCatItems] = useState([]);
    const [selectedBrandItems, setSelectedBrandItems] = useState([]);

    var category = [
        { id: 1, name: "Electronics"},
        { id: 2, name: "Mobiles & Accessories"},
        { id: 3, name: "Laptop & Accessories"},
        { id: 4, name: "TV & Home Entertainment"},
        { id: 5, name: "Audio"},
        { id: 6, name: "Cameras"},
        { id: 7, name: "Smart Technology"}
    ]

    var brands = [
        { id: 1, name: "Apple"},
        { id: 2, name: "Samsung"},
        { id: 3, name: "OnePlus"},
        { id: 4, name: "Realme"},
        { id: 5, name: "Redmi"},
        { id: 6, name: "Motorola"},
        { id: 7, name: "OPPO"},
        { id: 8, name: "Vivo"}
    ]

    var prices = [
        { id: 1, name: "10% Off or more"},
        { id: 2, name: "25% Off or more"},
        { id: 3, name: "35% Off or more"},
        { id: 4, name: "50% Off or more"},
        { id: 5, name: "60% Off or more"},
        { id: 6, name: "70% Off or more"}
    ]

    const handleInputChangeByCategory = (event) => {
        if(event.target.checked === true)
        {
            if (selectedCatItems.includes(event.target.value)) {
                setSelectedCatItems(selectedCatItems.filter((selectedItem) => selectedItem !== event.target.value));
            } else {
                setSelectedCatItems([...selectedCatItems, event.target.value]);
              } 
        }   
        else
        {
            setSelectedCatItems(selectedCatItems.filter((selectedItem) => selectedItem !== event.target.value));
        } 
    };

    const handleInputChangeByBrand = (event) => {
        if(event.target.checked === true)
        {
            if (selectedBrandItems.includes(event.target.value)) {
                setSelectedBrandItems(selectedBrandItems.filter((selectedItem2) => selectedItem2 !== event.target.value));
              } else {
                setSelectedBrandItems([...selectedBrandItems, event.target.value]);
              }
        }   
        else
        {
            setSelectedBrandItems(selectedBrandItems.filter((selectedItem2) => selectedItem2 !== event.target.value));
        }
    };

    useEffect(() => {
        var searchParams = {
            categories : selectedCatItems,
            brands : selectedBrandItems
        }
        props.searchData(searchParams)
      }, [selectedCatItems,selectedBrandItems]);

    return ( 
            <div className="col-md-12 p-0">
                <div className="sidenav">
                    <div className="row">
                        <div className="col-md-12 sidenav-heading">
                            <h5>Category</h5>
                        </div>
                        <div className="col-md-12 sidenav-content">
                            <ul className="list-unstyled">
                                {
                                    category.map((item,i) => {
                                        return (
                                            <li key={i}>
                                                <div className="form-check mb-3">
                                                    <label className="form-check-label">
                                                        <input className="form-check-input" type="checkbox" name={item.name} value={item.id} onChange={handleInputChangeByCategory} /> {item.name}
                                                    </label>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 sidenav-heading">
                            <h5>Brands</h5>
                        </div>
                        <div className="col-md-12 sidenav-content">
                            <ul className="list-unstyled">
                                {
                                    brands.map((item,i) => {
                                        return (
                                            <li key={i}>
                                                <div className="form-check mb-3">
                                                    <label className="form-check-label">
                                                        <input className="form-check-input" type="checkbox" name={item.name} value={item.id} onChange={handleInputChangeByBrand} /> {item.name}
                                                    </label>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 sidenav-heading">
                            <h5>Prices</h5>
                        </div>
                        <div className="col-md-12 sidenav-content" style={{ borderBottom: 0 }}>
                            <ul className="list-unstyled">
                                {
                                    prices.map((item,i) => {
                                        return (
                                            <li key={i}>
                                                <div className="form-check mb-3">
                                                    <label className="form-check-label">
                                                        <input className="form-check-input" type="checkbox" name={item.name} /> {item.name}
                                                    </label>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
    )
}

