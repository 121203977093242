import React from "react";

export default function Training() {
    return (
        <div className="row justify-content-center">
            <div className="col-md-12 card-box">
                <div className="card-body">
                    <p>Training is teaching, or developing in oneself or others, any skills and knowledge or fitness that relate to specific useful competencies. Training has specific goals of improving one's capability, capacity, productivity and performance.</p>
                </div>
            </div>
        </div>
    )
}

